import React, { useState, useEffect } from 'react'
import moment from "moment";
import { pubDroitePDC, insert_stat_pub, compte_pubDroite, update_stat_pubDejaClique, update_stat_pub2 } from '../../api/pubs'

const Pub_droite_PDC = () => {

    const [pub, setPubDroite] = useState(0)
    const [stats_pub_d, setStatsPubDroite] = useState()
    const [vus, setVus] = useState("")
    const [vus_total, setVusTotal] = useState("")

    useEffect(() => {
        getPubDroite()
    }, [])

    const getPubDroite = () => {
        pubDroitePDC()  // Sélectionne la pub de droite qui est en ligne
            .then((res) => {
                if (res !== undefined) {
                    //console.log("Stats de la pub : ", res.id)
                    setPubDroite(res)
                    compte_pubDroite(res.id) // Récupère le nombre de vues du jour
                        .then((res) => {
                            setStatsPubDroite(res)
                            if (res === undefined) {
																							   
                                // pas d'entrée aujourd'hui, met les vus à 0
                                setVus(0)
                                setVusTotal(0)
                            }
                            else {
																												
                                // déja une entrée aujourd'hui, on compte les nombres de vus
                                setVus(res[0].vus)
                                setVusTotal(res[0].vus_total)
                            }
                        })
                        .catch(() => { 
                             //console.log("Pas d'entrée") 
                        })
                }
            })
            .catch(err => console.log(err))
    
    }

							  
										  
    const addStat = (infos_pub) => {
        //insert_stat_pub(infos_pub)
        const store_pub_droite = {
            id_pub: pub.id,
            jour: moment().format("YYYY-MM-DD")
        }

        //console.log("stats_pub_d ", stats_pub_d.length)
        if (stats_pub_d.length === 0) {
																							
            // pas d'entrée aujourd'hui, on crée une entrée pour ce jour
            insert_stat_pub(infos_pub)
            getPubDroite()
        }
        else {
            // déjà une entrée créée ce jour
            // on vérifie si l'user a déjà cliqué sur cette pub aujourd'hui
																							   
            const checkLocalStorage_pud_d_PDC = JSON.parse(localStorage.getItem('pub_droite_PDC'));

            if (checkLocalStorage_pud_d_PDC) {
                if (checkLocalStorage_pud_d_PDC.jour === moment().format("YYYY-MM-DD")) {
                    // Déjà cliqué aujourd'hui !!!!
																	   
                    const update1 = {
                        id_pub: infos_pub.id,
                        vus_total: vus_total
                    }
                    update_stat_pubDejaClique(update1)
                }
                else {
                    // Pas encore cliqué ce jour.......
																										
                    const update2 = {
                        id_pub: infos_pub.id,
                        vus: vus
                    }
                    update_stat_pub2(update2)
                }
            }
            else {
                // Rien dans le local storage
															   
                const update2 = {
                    id_pub: infos_pub.id,
                    vus: vus
                }
												  
                update_stat_pub2(update2)
            }
            // quoi qu'il en soit, on mets dans le local storage le fait qu'il ai cliqué sur la pub
            localStorage.setItem("pub_droite_PDC", JSON.stringify(store_pub_droite));
        }
    }

    //console.log("stats_pub_d", stats_pub_d)


    const repertoire = "https://www.formation-continue-imagerie.fr/images/bup";

    return (
        <div>
            {pub !== 0 ? (
                <a target="_blank" rel="noopener noreferrer" href={pub.www}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        addStat(pub)
                    }}
                >
                    <img
                        className="pub"
                        src={`${repertoire}/${pub.repertoire}/${pub.image}`}
                        alt="zimage"
                    />
                </a>
            ) : <a target="_blank" rel="noopener noreferrer" href={window.location.href}
                onMouseDown={(e) => {
                    e.preventDefault()
                    addStat(pub)
                }}
            >
                <img
                    className="pub"
                    src={`${repertoire}/default/default.jpg`}
                    alt="zimage"
                />
            </a>
            }
        </div>
    );



}
export default Pub_droite_PDC

/*
                <a target="_blank" href={pub.www}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        addStat(pub)
                    }}
                >

*/