import React from 'react'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import CookieConsent, { Cookies } from "react-cookie-consent";

const Footer = (props) => {


    if (!process.env.HOST_BD) {
        // nous sommes en local
        //console.log("Local")
        ReactGA.initialize("G-G0EM3YG0YN");
        ReactGA.send("pageview");
    } else {
        // nous sommes en ligne
        ReactGA.initialize("G-G0EM3YG0YN");
        ReactGA.send("pageview");
    }


    return (
        <footer>
            <p className="centre">
                <Link className="lien" rel="nofollow" to="https://clairance-creatinine.fr/0_mail"> Contact </Link> 
                /  <Link className="lien" rel="nofollow" to="/Mentions_legales">Mentions légales</Link>
            </p>

<CookieConsent
  location="bottom"
  buttonText="J'accepte"
  cookieName="cookie_clairance"
  style={{ background: "#000" }}
  buttonStyle={{ color: "#4e503b", fontSize: "10px", margin: "0px 15px", backgroundColor: "#fff", borderRadius: "5px", }}
  expires={150}
>
  <span className="cookies">Ce site utilise des cookies pour vous offrir le meilleur service. En poursuivant votre navigation, vous acceptez l’utilisation des cookies.</span>
</CookieConsent> 
        </footer>
    )
}
export default Footer