import React from 'react'
import { HelmetProvider } from 'react-helmet-async';
import './App.css';

import Header from './pages/includes/header'
import Footer from './pages/includes/Footer'
//import Home from './pages/home'
import Oncologie from './pages/PDC_Oncologie';
import Oncologie2 from './pages/PDC_Oncologie_debit';
import Vasculaire from './pages/PDC_Vasculaire';
import Clairance from './pages/Clairance_MDRD_COCKROFT';
import Clairance_CKP from './pages/Clairance_CKP';
import MentionsLegales from './pages/Mentions_legales';
import OptiDose from './pages/AppliOptidose';
import AppliClairance from './pages/AppliClairance';
//import Admin from './pages/Admin/admin';
//import Pubs from './pages/Admin/pubs';
//import Stats from './pages/Admin/stats';
import Error from './pages/includes/Error';


import {Routes, Route} from 'react-router-dom'


function App() {

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
    <div className="App">
      <Header/>
      <Routes>
          <Route exact path="/" element={<Clairance_CKP />}/>
          <Route exact path="/Oncologie" element={<Oncologie />}/>
          <Route exact path="/Oncologie2" element={<Oncologie2 />}/>
          <Route exact path="/Vasculaire" element={<Vasculaire />}/>
          <Route exact path="/Clairance" element={<Clairance />}/>
          <Route exact path="/Clairance_CKP" element={<Clairance_CKP />} />
          <Route exact path="/Mentions_Legales" element={<MentionsLegales />} />
          <Route exact path="/Mentions-Legales" element={<MentionsLegales />} />
          <Route exact path="/OptiDose" element={<OptiDose />} />
          <Route exact path="/AppliClairance" element={<AppliClairance />} />
          <Route path="*" element={<Error />} />
      </Routes>
      <Footer/>
    </div>
</HelmetProvider>
  );
}

export default App;

/*

          <Route exact path="/Mendoz" element={<Admin />} />
          <Route exact path="/Mendoz/Pubs" element={<Pubs />} />
          <Route exact path="/Mendoz/Stats" element={<Stats />} />

*/
