import { useEffect } from "react";
import {addPDConco} from '../../api/stats'

export function useAutoSavePDConco(storageId, data, delay = 2000) {
    useEffect(() => {
        const timeoutId = setTimeout(() => {

            if (data.resultats !== null) {
                console.log("Sauvegarde des données PDC onco", data.resultats);
                localStorage.setItem(storageId, JSON.stringify(data));
                addPDConco(data)
            }
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [delay, data, storageId]);
}


export default useAutoSavePDConco