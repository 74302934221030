export const config = {
	api_url: "https://back2.clairance-creatinine.fr", // nginx
	api_url_secours: "https://back.clairance-creatinine.fr", // nginx

	
	/*// local
	api_url: "http://localhost:5000", 
	*/

	/* // prod 
	api_url: "https://back2.clairance-creatinine.fr", // nginx
	api_url_secours: "https://back.clairance-creatinine.fr", // nginx
	*/

	// api_url: "http://51.91.79.59:3306", // OVH 3 

	// api_url: "https://back.clairance-creatinine.fr", // nginx
};
