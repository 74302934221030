import React, { useState, useEffect } from 'react'
import ReactSlider from "react-slider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import './styles.css';
import { Helmet } from 'react-helmet-async';
//import './includes/styles_ref.css';
import Interpretation from './includes/Interpretation'
import Actu from './includes/Actu'
import useAutoSaveMDRD from "./BDD/BDD_mdrd"
import Pub_droite from './pubs/pub_droite'

const Clairance = () => {
  let meta_title ="Calcul de la clairance de la créatinine selon Cockroft & Gault et MDRD"
  let meta_cannonical ="https://clairance-creatinine.fr/Clairance"
  let meta_description ="La clairance est indispensable avant toute injection de produit de contraste au scanner. Le calcul se fait selon les formules Cockroft & Gault et MDRD"

  var anneeActuelle = new Date().getFullYear();
  const [poids, setPoids] = useState("");
  const [annee, setAnnee] = useState("");
  const [age, setAge] = useState(0);
  const [creatinine, setCreatinine] = useState("");
  const [resultats, setResultats] = useState(null);
  const [cockroft, setCockroft] = useState("");
  const [mdrd, setMdrd] = useState("");
  const [sexe, setSexe] = useState("");
  const [sexe2, setSexe2] = useState("");
  const [afro, setAfro] = useState(1);
  const [methodeCalcul, setMethodeCalcul] = useState("");
  const [limitAge, setLimitAge] = useState(null);
  const [limitPoids, setLimitPoids] = useState(null);
  const [limitCreat, setLimitCreat] = useState(null);
  const [couleur, setCouleur] = useState("");
  const [fonction, setFonction] = useState("");

  const Reset = () => {
    setResultats(null)
    setPoids("")
    setCreatinine("")
    setAge("")
    setAnnee("")
    setSexe("")
    setSexe2("")
    setMethodeCalcul("")
    setCockroft("")
    setMdrd("")
  };

  const donnees = {
    annee : annee,
    age : age,
    sexe : sexe,
    poids : poids,
    afro : afro,
    creatinine : creatinine,
    clairance : resultats
  }

  // C'est ici pour sauvegarder les résultats dans le BDD
  useAutoSaveMDRD("donnees", donnees)

  function calculAge() {
    if (annee !== "") {
      var age = anneeActuelle - annee;
      setAge(age);
    }
  }


  const calc_cockroft = () => {
    setResultats(null)
    let xcockroft = ((((140 - age) * poids * sexe2) / creatinine))
    setCockroft(xcockroft.toFixed(1))
    setResultats(xcockroft.toFixed(1))
  }

  const calc_mdrd = () => {
    setResultats(null)
    let xmdrd = (186.3 * (Math.pow((creatinine / 88.4), -1.154))) * (Math.pow(age, -0.203)) * sexe2 * afro
    setMdrd(xmdrd.toFixed(1))
    setResultats(xmdrd.toFixed(1))
    //console.log("mdrd", mdrd)
  }


  useEffect(() => {
    document.title = "Calcul de la clairance de la créatinine selon Cockroft & Gault et MDRD V2";
  }, []);


  useEffect(() => {

    calculAge()

    if (poids === 150) { setLimitPoids(true) }
    else { setLimitPoids("") }

    if (creatinine === 250) { setLimitCreat(true) }
    else { setLimitCreat("") }

    if (annee === 1910) {
      setAge("");
      setAnnee("")
      setMethodeCalcul("")
    }
    else if (age > 0 && age < 65) {  // calcul selon Cockroft
      setMethodeCalcul("Cockroft et Gault")
      setResultats(null)
      if (poids != "" && creatinine != "" && sexe != "") {
        calc_cockroft()
        if (sexe === "Homme") { setSexe2(1.23) }
        else if (sexe === "Femme") { setSexe2(1.04) }
        else { setSexe2(null) }
      }

    }
    else if (age > 65) {  // calcul selon MDRD
      setMethodeCalcul("MDRD")
      if (creatinine != "" && sexe != "" && afro != "") {
        calc_mdrd()
        if (sexe === "Homme") { setSexe2(1) }
        else if (sexe === "Femme") { setSexe2(0.742) }
        else { setSexe2(null) }
      }
    }
    else { setMethodeCalcul("") }

    if (age > 130) { setLimitAge(true); setResultats(null) }
    else { setLimitAge("") }

    if (resultats > 0 && resultats < 30) { setCouleur("noir"); setFonction("Stade 4 : Insuffisance rénale sévère"); }
    if (resultats >= 30 && resultats < 45) { setCouleur("rouge"); setFonction("Stade 3B : Insuffisance rénale modérée"); }
    if (resultats >= 45 && resultats < 60) { setCouleur("rouge"); setFonction("Stade 3A : Insuffisance rénale modérée"); }
    if (resultats >= 60 && resultats < 90) { setCouleur("orange"); setFonction("Stade 2 : Insuffisance rénale légère"); }
    if (resultats >= 90 && resultats <= 2000) { setCouleur("vert"); setFonction("Valeurs normales"); }

  }, [calculAge]);

  return (
    <div>
    <Helmet>
      <title>{meta_title}</title>
      <link rel="canonical" href={meta_cannonical} />
      <meta name='description' content={meta_description} />
    </Helmet>
    <div className='flex1'>

      <div className='resultats_clairance' >

        {resultats !== null ? (
          <div className={`ok ${couleur}`}>
            <p className='examen clairance'>Méthode de calcul :  <span className='bold'>{methodeCalcul} </span> </p>
            <p className='injection'>{resultats} *</p>
            <p className='examen clairance'><span className='bold'>{fonction} </span></p>
          </div>
        ) : (
          <div className='attente' >
            <p className='attente_texte'>Les résultats s'afficheront içi quand les critères indispensables seront renseignés</p>
          </div>
        )
        }
      </div>
      <div className="main">
        <div className="gauche">
          <h1 className='titreClairance' >Calcul de la clairance de la créatinine </h1>
          <h2 className='titreClairance'>selon Cockroft & Gault et MDRD <FontAwesomeIcon className="gomme" icon={faEraser} onClick={() => { Reset() }} /></h2>

          <div className='div_clairance'>
            <div className="zzzz">
              <div className="InputAddOn">
                <span className="InputAddOn-item">Année de naissance</span>
                <input
                  className="InputAddOn-field"
                  placeholder="année"
                  value={annee}
                  onChange={(annee) => {
                    setAnnee(annee.target.value)
                  }}
                />
              </div>
              <ReactSlider
                className="customSlider"
                thumbClassName="customSlider-thumb"
                trackClassName="customSlider-track"
                value={annee}
                min={1910}
                max={anneeActuelle}
                defaultValue={0}
                onChange={(value) => setAnnee(value)}

              />

            </div>

            <div className="zzzz">
              <div className="InputAddOn">
                <span className="InputAddOn-item">Age</span>
                <input
                  className="InputAddOn-field"
                  placeholder="ans"
                  value={age}
                  onChange={(age) => {
                    setAge(age.target.value)
                  }}
                />
                <span className="InputAddOn-item">ans</span>
              </div>
              {limitAge !== "" && (<p className='errorPatient' >Age incorrect</p>)}


            </div>
          </div>

          <div className='div_clairance'>


            <div className="zzzz">
              <div className="InputAddOn">
                <span className="InputAddOn-item">Taux de créatinine</span>
                <input
                  className="InputAddOn-field"
                  placeholder="µmol/L"
                  value={creatinine}
                  onChange={(creatinine) => {
                    setCreatinine(creatinine.target.value)
                  }}
                />
                <span className="InputAddOn-item">µmol/L</span>
              </div>

              <ReactSlider
                className="customSlider"
                thumbClassName="customSlider-thumb"
                trackClassName="customSlider-track"
                value={creatinine}
                min={20}
                max={250}
                defaultValue={0}
                onChange={(value) => setCreatinine(value)}
              />
            </div>

            <div className="zzzz">
              <h2 className='titre'>Sexe</h2>
              <div className='radio2'>
                <label className={`label_group_row btn_clairance ${sexe === "Homme" ? "checked" : ""}`}>Homme
                  <input className='radio'
                    name="sexe"
                    label="Homme"
                    type="radio"
                    defaultChecked={sexe === "Homme"}
                    onClick={() => setSexe("Homme")}
                  />
                </label>
                <label className={`label_group_row btn_clairance ${sexe === "Femme" ? "checked" : ""}`}>Femme
                  <input className='radio'
                    name="sexe"
                    label="Femme"
                    type="radio"
                    defaultChecked={sexe === "Femme"}
                    onClick={() => setSexe("Femme")}
                  />
                </label>
              </div>

            </div>
          </div>


          {limitCreat !== "" && (<p className='errorPatient' >Rentrez manuellement le taux de créatinine si supérieur</p>)}

          <div className='div_clairance'>


            {methodeCalcul === "Cockroft et Gault" ? (

              <div className="aaaa">
                <div className="InputAddOn">
                  <span className="InputAddOn-item">Poids</span>
                  <input
                    className="InputAddOn-field"
                    placeholder="kg"
                    value={poids}
                    onChange={(poids) => {
                      setPoids(poids.target.value)
                      console.log("Poids", poids.target.value)
                    }}
                  />
                  <span className="InputAddOn-item">kg</span>
                </div>
                <ReactSlider
                  className="customSlider"
                  thumbClassName="customSlider-thumb"
                  trackClassName="customSlider-track"
                  value={poids}
                  min={49}
                  max={150}
                  defaultValue={0}
                  onChange={(value) => setPoids(value)}

                />

              </div>
            )
              : null
            }

            {methodeCalcul === "MDRD" ? (
              <div className="aaaa">
                <h2 className='titre'>Patient à peau noire</h2>
                <div className='radio2'>
                  <label className={`label_group_row btn_clairance ${afro === 1.21 ? "checked" : ""}`}>Oui
                    <input className='radio'
                      name="afro"
                      label="Oui"
                      type="radio"
                      defaultChecked={afro === 1.21}
                      onClick={() => setAfro(1.21)}
                    />
                  </label>
                  <label className={`label_group_row btn_clairance ${afro === 1 ? "checked" : ""}`}>Non
                    <input className='radio'
                      name="afro"
                      label="Non"
                      type="radio"
                      defaultChecked={afro === 1}
                      onClick={() => setAfro(1)}
                    />
                  </label>
                </div>
              </div>
            )
              : null
            }


          </div>

          {limitPoids !== "" && (<p className='errorPatient' >Rentrez manuellement le poids si supérieur à  150kg</p>)}

        </div>
        <div className="droite">
          <Pub_droite />
        </div>

      </div>
      </div>
      <div className="well centre noir">
        <Link className='lien' to="/"><h3>Revenir vers le calculateur CKD-EPI faisant référence</h3></Link>
      </div>
      <div className="container">
        <Actu />
      </div>
      <div className="flex1">
        <Interpretation />
      </div>
    </div>
  )
}

export default Clairance