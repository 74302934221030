import React from 'react'
import './../styles.css';

const Interpreation = () => {
    return (
        <div>
            <h2 className="centre">Interprétation des résultats</h2>
            <hr />
            <div className="interpretation">
                <div className="w50">
                    <div className="well centre vert"><p>de 80 à 120 ml/min : Valeurs normales</p></div>
                    <div className="well centre orange"><p>entre 60 et 80 ml/min : Insuffisance rénale légère</p></div>
                    <div className="well centre rouge"><p>entre 30 et 60 ml/min : Insuffisance rénale modérée</p></div>
                    <div className="well centre noir"><p>&lt;  30 ml/min : Insuffisance rénale sévère</p></div>
                    <p className="centre">Pour plus d'information consultez la brochure de la socièté française de radiologie <a href="https://www.sfrnet.org/rc/org/sfrnet/htm/Article/2004/mie-20041216-000000-08198/src/htm_fullText/fr/fiche%20rein.pdf" className="lien2" >SFR</a></p>

                </div>


                <div className="w50">
                    <h3 className="centre">Formules du calcul</h3>
                    <p><u><a id="cockroft">Clairance de la créatinine selon Cockroft et Gault </a></u></p>
                    <p className="maigre"> - Chez l'<b>homme</b> = 1.23 x <b>Poids (kg)</b> x (140-<b>âge</b>) / <b>créatinine (µmol/l)</b> </p>
                    <p className="maigre"> - Chez la <b>femme</b> = 1.04 x <b>Poids (kg)</b> x (140-<b>âge</b>) / <b>créatinine (µmol/l)</b></p>
                    <br />
                    <p><u><a id="mdrd">Clairance de la créatinine selon <abbr title="Modification of Diet in Renal Disease">MDRD</abbr>   </a></u></p>
                    <p className="maigre"> MDRD = Modification of Diet in Renal Disease </p>
                    <p className="maigre"> - Chez l'<b>homme</b> = 186 x (<b>créatinine (µmol/l)</b> x 0,0113)<sup>-1,154</sup> x <b>âge</b><sup>- 0,203</sup></p>
                    <p className="maigre"> &nbsp;  &nbsp;  &nbsp;  &nbsp; x 1,21	pour les <b>sujets d'origine africaine</b>  </p>
                    <p className="maigre"> &nbsp;  &nbsp;  &nbsp;  &nbsp; x 0.742	pour les <b>femmes</b></p>
                    <br />
                    <p><u><a id="CKP-EPI">Clairance de la créatinine selon la formule CKD-EPI    </a></u></p>
                    <p className="maigre"> CKD-EPI = Chronic Kidney Disease - EPIdemiology </p>
                    <p className="maigre"> DFGe = 141 x min(<b>créatinine</b>/<b>k</b> , 1)<sup><b>α</b></sup> x max(<b>créatinine</b>/<b>k</b> , 1)<sup>-1.209</sup> x 0.993<sup>âge</sup> </p>
                    <p className="maigre"> &nbsp;  &nbsp;  &nbsp;  &nbsp; x 1.159	pour les <b>sujets de race noire</b>  </p>
                    <p className="maigre"> &nbsp;  &nbsp;  &nbsp;  &nbsp; x 1.018	pour les <b>femmes</b></p>
                    <p className="maigre"><b>k</b> : 61.9 (femmes) ou 79.6 (hommes)</p>
                    <p className="maigre"><b>α</b> : -0.329 (femmes) or -0.411 (hommes)</p>
                </div>
            </div>

            <h2 className="centre">Références</h2>
            <hr />

            <p><a href="https://www.sfndt.org/sn/PDF/epro/ICAR/2005-04.pdf" ><span>Données récentes sur l'estimation de la fonction rénale : <b>Formule de Cockcroft ou MDRD ?</b></span></a> ,</p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <b><i>Yvon Berland</i></b>, Marseille, <b><i>Gilbert Deray</i></b>, Paris, <b><i>Maurice Laville</i></b>, Lyon</p>
            <p><a href="https://medicalforum.ch/docs/smf/archiv/fr/2006/2006-18/2006-18-254.pdf"><span>Appréciation de la fonction rénale</span></a> ,</p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <b><i>Dimitrios Tsinalis, Isabelle Binet</i></b>, Néphrologie, Hôpital Cantonal de St-Galln</p>
            <p><a href="https://www.sfrnet.org/rc/org/sfrnet/htm/Article/2004/mie-20041216-000000-08198/src/htm_fullText/fr/fiche%20rein.pdf"><span>Prévention de l'insuffisance rénale induite par les produits de contraste iodes</span></a> ,</p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <b><i>Fiche de recommandation pour la pratique clinique</i></b>, Société Française de Radiologie</p>

            <div className="errorPatient" role="alert"><sup>*</sup> Le résultat du calcul de la clairance est donné à titre indicatif. Le site <strong>www.clairance-creatinine.fr</strong>  ne peut être tenue pour responsable en cas de conséquences indésirables.</div>

        </div>
    )
}
export default Interpreation