import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import "./includes/styles_ref.css";
import useAutoSavePDCvasc from "./BDD/BDD_PDC_vasculaire";
import Pub_droite_PDC from "./pubs/pub_droite_PDC";

const Vasculaire = (props) => {
  let meta_title ="Optimisation de l'injection de produit de contraste au scanner (TDM) -- Vasculaire"
  let meta_cannonical ="https://clairance-creatinine.fr/Vasculaire"
  let meta_description ="Cet outil permet d'optimiser les doses de PDC au scanner en fonction de chaque examen "

  const [examen, setExamen] = useState("");
  const [patient, setPatient] = useState("");
  const [tempsIV, setTempsIV] = useState("");
  const [debitIode, setDebitIode] = useState("");
  const [concentration, setConcentration] = useState("");
  const [volume, setVolume] = useState("");
  const [debit, setDebit] = useState("");
  const [resultats, setResultats] = useState(null);
  const [attente, setAttente] = useState("ok");
  const [showMore, setShowMore] = useState(false);
  const [showPatient, setShowPatient] = useState(false);
  const [casParticulier, setCasParticulier] = useState(false);

  useEffect(() => {
    document.title =
      "Optimisation de l'injection de produit de contraste au scanner (TDM) -- Vasculaire";
  }, []);

  ReactGA.initialize([
    {
      trackingId: "UA-552881-6",
    },
  ]);

  const Reset = () => {
    setExamen("");
    setConcentration("");
    setExamen("");
    setPatient("");
    setAttente("ok");
    setShowPatient(false);
    setShowMore(false);
  };

  const donnees = {
    examen: examen + " " + patient,
    concentration: concentration,
    volume: volume,
    debit: debit,
    resultats: resultats,
  };
  //console.log("donnees", donnees)
  // C'est ici pour sauvegarder les résultats dans le BDD
  useAutoSavePDCvasc("donnees", donnees);

  const vasc_volume = () => {
    let volume =
      Math.round(((tempsIV * debitIode) / concentration) * 1000 * 1) / 1;
    setVolume(volume);

    if (
      isNaN(volume) ||
      volume === Infinity ||
      volume === 0 ||
      (examen === "Embolie pulmonaire" && !patient)
    ) {
      setResultats(null);
      setAttente("ok");
    } else {
      setResultats(volume);
      setAttente(null);
    }
  };

  const vasc_debit = () => {
    let xdebit = Math.round((debitIode / concentration) * 1000 * 10) / 10;
    setDebit(xdebit);
  };

  useEffect(() => {
    vasc_volume();
    vasc_debit();
    if (examen === "Embolie pulmonaire") {
      setShowPatient(true);

      if (
        examen === "Embolie pulmonaire" &&
        patient === "Sans particularité ou âgé"
      ) {
        setTempsIV("10");
        setDebitIode("1.4");
        setCasParticulier("chez un patient sans particularité ou âgé");
        if (volume !== Infinity) {
          setResultats(volume);
        }
      } else if (
        examen === "Embolie pulmonaire" &&
        patient === "Sportif, femme enceinte"
      ) {
        setTempsIV("10");
        setDebitIode("1.8");
        setCasParticulier("chez une femme enceinte");
        if (volume !== Infinity) {
          setResultats(volume);
        }
      } else if (
        examen === "Embolie pulmonaire" &&
        patient === "Patient obèse"
      ) {
        setTempsIV("10");
        setDebitIode("2");
        setCasParticulier("chez un patient obése");
        if (volume !== Infinity) {
          setResultats(volume);
        }
      } else {
      }
    } else if (examen === "Coroscanner") {
      setTempsIV("14.5");
      setDebitIode("1.4");
      setShowPatient(false);
      setCasParticulier("");
      //setConcentration("400")
    } else if (examen === "Coroscanner FLASH") {
      setTempsIV("16.5");
      setDebitIode("1.4");
      setShowPatient(false);
      setCasParticulier("");
      //setConcentration("400")
    } else if (examen === "Aorte abdominale") {
      setTempsIV("26.5");
      setDebitIode("1.1");
      setShowPatient(false);
      setCasParticulier("");
      //setConcentration("400")
    } else if (examen === "Aorte abdominale FLASH") {
      setTempsIV("29");
      setDebitIode("1.1");
      setShowPatient(false);
      setCasParticulier("");
      //setConcentration("400")
    } else if (examen === "Membres inférieurs") {
      setTempsIV("36.5");
      setDebitIode("1.1");
      setShowPatient(false);
      setCasParticulier("");
      //setConcentration("400")
    } else {
    }
  }, [vasc_volume]);

  const Resultats = () => {
    return (
      <div className="ok">
        <p className="examen">
          Pour l'examen <span className="bold">{examen} </span> à{" "}
          <span className="bold">120 KV**</span>
        </p>
        <p className="examen"> {casParticulier}</p>
        <p className="injection">
          {volume} ml à {debit} cc/s*
        </p>

        {resultats !== null && (
          <div className="plus">
            <p
              className="plusBouton"
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              {showMore
                ? " - Moins d'informations"
                : " + Plus d'informations et recommandations"}
            </p>
            {showMore ? <Details /> : null}
          </div>
        )}
      </div>
    );
  };

  const Details = () => {
    return (
      <div className="plus">
        <p className="centre">
          Concentration : <span className="bold">{concentration} mgI/ ml</span>{" "}
        </p>
        <p className="centre">
          Charge en iode : <span className="bold">{debitIode} g iode/s</span>
        </p>
        <p className="centre">
          Temps d'injection : <span className="bold">{tempsIV} s</span>
        </p>
        <hr />
        <p className="plusDetail">
          -{" "}
          <span className="bold">
            Préchauffer le produit de contraste à 37°C
          </span>{" "}
          pour diminuer la viscosité et donc la pression d’injection.
        </p>
        <p className="plusDetail">- Abord veineux le plus proximal possible.</p>
        <p className="plusDetail">
          - Utiliser un cathlon le plus gros possible (vert).
        </p>
        <p className="plusDetail">
          -{" "}
          <span className="f20">
            Le temps d'injection est de{" "}
            <span className="bold">{tempsIV} secondes</span>.
          </span>
        </p>
        <p className="plusDetail">
          - L'injection doit être terminée avant la fin de l’acquisition.
        </p>
        <p className="plusDetail">
          - Rinçage avec{" "}
          <span className="bold">30 à 40 ml de sérum physiologique</span> pour
          pousser le PdC résiduel de la tubulure et du réseau veineux proximal.
        </p>
      </div>
    );
  };

  return (
    <div className="flex1">
    <Helmet>
      <title>{meta_title}</title>
      <link rel="canonical" href={meta_cannonical} />
      <meta name='description' content={meta_description} />
    </Helmet>
      <div className="resultats_vasculaire">
        {attente !== null && (
          <div className="attente">
            <p className="attente_texte">
              Les résultats s'afficheront içi quand les critères indispensables
              seront renseignés
            </p>
          </div>
        )}
        {resultats !== null && (
          <>
            <Resultats />
          </>
        )}
      </div>
      <div className="main">
        <div className="gauche">
          <h2 className="titre">
            Mon examen :{" "}
            <FontAwesomeIcon
              className="gomme"
              icon={faEraser}
              onClick={() => {
                Reset();
              }}
            />
          </h2>
          <div className="input_patient">
            <label
              className={`label_group ${
                examen === "Embolie pulmonaire" ? "checked" : ""
              }`}
            >
              <span>Embolie pulmonaire</span>
              <input
                className="radio"
                name="patient"
                label="Embolie pulmonaire"
                type="radio"
                defaultChecked={examen === "Embolie pulmonaire"}
                onClick={() => setExamen("Embolie pulmonaire")}
              />
            </label>

            {showPatient ? (
              <div className="app">
                <h3>Mon patient : </h3>
                <label
                  className={`label_group w60 ${
                    patient === "Sans particularité ou âgé" ? "checked" : ""
                  }`}
                >
                  Sans particularité ou âgé
                  <input
                    className="radio"
                    name="EP"
                    label="Sans particularité ou âgé"
                    type="radio"
                    defaultChecked={patient === "Sans particularité ou âgé"}
                    onClick={() => setPatient("Sans particularité ou âgé")}
                  />
                </label>
                <label
                  className={`label_group w60 ${
                    patient === "Sportif, femme enceinte" ? "checked" : ""
                  }`}
                >
                  Sportif, femme enceinte
                  <input
                    className="radio"
                    name="EP"
                    label="Sportif, femme enceinte"
                    type="radio"
                    defaultChecked={patient === "Sportif, femme enceinte"}
                    onClick={() => setPatient("Sportif, femme enceinte")}
                  />
                </label>
                <label
                  className={`label_group w60 ${
                    patient === "Patient obèse" ? "checked" : ""
                  }`}
                >
                  Patient obèse
                  <input
                    className="radio"
                    name="EP"
                    label="Patient obèse"
                    type="radio"
                    defaultChecked={patient === "Patient obèse"}
                    onClick={() => setPatient("Patient obèse")}
                  />
                </label>
              </div>
            ) : (
              <div>
                <label
                  className={`label_group ${
                    examen === "Coroscanner" ? "checked" : ""
                  }`}
                >
                  Coroscanner
                  <input
                    className="radio"
                    name="patient"
                    label="Coroscanner"
                    type="radio"
                    defaultChecked={examen === "Coroscanner"}
                    onClick={() => setExamen("Coroscanner")}
                  />
                </label>
                <label
                  className={`label_group ${
                    examen === "Coroscanner FLASH" ? "checked" : ""
                  }`}
                >
                  Coroscanner FLASH
                  <input
                    className="radio"
                    name="patient"
                    label="Coroscanner FLASH"
                    type="radio"
                    defaultChecked={examen === "Coroscanner FLASH"}
                    onClick={() => setExamen("Coroscanner FLASH")}
                  />
                </label>
                <label
                  className={`label_group ${
                    examen === "Aorte abdominale" ? "checked" : ""
                  }`}
                >
                  Aorte abdominale
                  <input
                    className="radio"
                    name="patient"
                    label="Aorte abdominale"
                    type="radio"
                    defaultChecked={examen === "Aorte abdominale"}
                    onClick={() => setExamen("Aorte abdominale")}
                  />
                </label>
                <label
                  className={`label_group ${
                    examen === "Aorte abdominale FLASH" ? "checked" : ""
                  }`}
                >
                  Aorte abdominale FLASH
                  <input
                    className="radio"
                    name="patient"
                    label="Aorte abdominale FLASH"
                    type="radio"
                    defaultChecked={examen === "Aorte abdominale FLASH"}
                    onClick={() => setExamen("Aorte abdominale FLASH")}
                  />
                </label>
                <label
                  className={`label_group ${
                    examen === "Membres inférieurs" ? "checked" : ""
                  }`}
                >
                  Membres inférieurs
                  <input
                    className="radio"
                    name="patient"
                    label="Membres inférieurs"
                    type="radio"
                    defaultChecked={examen === "Membres inférieurs"}
                    onClick={() => setExamen("Membres inférieurs")}
                  />
                </label>
              </div>
            )}
          </div>

          <h2 className="titre">Concentration en mg/ml</h2>
          <div className="radio2">
            <label
              className={`label_group_row w20 ${
                concentration === 300 ? "checked" : ""
              }`}
            >
              300
              <input
                className="radio"
                name="concentration"
                label="300"
                type="radio"
                defaultChecked={concentration === 300}
                onClick={() => setConcentration(300)}
              />
            </label>
            <label
              className={`label_group_row w20 ${
                concentration === 350 ? "checked" : ""
              }`}
            >
              350
              <input
                className="radio"
                name="concentration"
                label="350"
                type="radio"
                defaultChecked={concentration === 350}
                onClick={() => setConcentration(350)}
              />
            </label>
            <label
              className={`label_group_row w20 ${
                concentration === 370 ? "checked" : ""
              }`}
            >
              370
              <input
                className="radio"
                name="concentration"
                label="370"
                type="radio"
                defaultChecked={concentration === 370}
                onClick={() => setConcentration(370)}
              />
            </label>
            <label
              className={`label_group_row w20 ${
                concentration === 400 ? "checked" : ""
              }`}
            >
              400
              <input
                className="radio"
                name="concentration"
                label="400"
                type="radio"
                defaultChecked={concentration === 400}
                onClick={() => setConcentration(400)}
              />
            </label>
          </div>
        </div>
        <div className="droite">
          <Pub_droite_PDC />
        </div>
      </div>

      <div className="bottom">
        <p>
          * Les résultats sont calculés à partir des recommandations du CIRTACI
          de la SFR. Ils sont donnés à titre indicatif.
        </p>
        <p>
          ** Les paramètres d'injection sont donnés pour une utilisation à
          120kV. Si vous travaillez déjà à kilivoltage inférieur, vous pouvez
          encore optimiser ces paramètres d'injection.
        </p>
      </div>

      <h2 className="centre">Références</h2>
      <p className="centre">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.radiologie.fr/sites/www.radiologie.fr/files/medias/documents/CIRTACI%20Fiche%20Ge%CC%81ne%CC%81ralite%CC%81s%20VASCULAIRE_5_3_1.pdf"
        >
          <img
            src="https://v1.clairance-creatinine.fr/PDC/images/cirtaci.jpg"
            alt="CIRTACI"
            title="CIRTACI"
          />
        </a>
      </p>

      <div className="well clear" id="optimisation">
        <h3 className="centre">
          Optimisation des doses de produits de contraste en tomodensitometrie
          Vasculaire
        </h3>
        <h4>I. Généralités</h4>
        <p className="text-indent">
          La justification et l’optimisation sont, comme pour les radiations
          ionisantes, à la base de l’utilisation des produits de contraste
          iodés. Ils ne doivent être injectés que s’ils peuvent apporter des
          informations utiles au diagnostic, avec la quantité d’iode nécessaire
          et suffisante. L’évolution technologique des scanners permet une
          acquisition rapide, une zone d’exploration large, une modulation des
          kV et des mAS, et une optimisation de la qualité d’image et des doses
          de RX grâce aux nouveaux algorithmes (reconstruction itérative et
          bientôt intelligence artificielle).
        </p>
        <p className="text-indent">
          Pour les protocoles vasculaires, c’est le débit d’administration
          d’iode qui est le facteur clé à optimiser. L’abaissement du
          kilovoltage permet de diminuer la dose d’exposition aux rayons X et la
          dose de produit de contraste nécessaire.
        </p>
        <p className="text-indent">
          Une optimisation des protocoles d’injection adaptée à chaque machine
          doit être réalisée.
        </p>
        <p className="text-indent">
          Cette fiche se propose une démarche à suivre méthodique pour optimiser
          les examens selon les protocoles classiques à 120 kV, en détaillant le
          protocole embolie pulmonaire qui est un des plus difficile à mettre en
          place puis pour optimiser la réduction d’exposition aux rayons X et de
          dose de contraste utile en réduisant les kV à 100.
        </p>

        <h4>II. Définitions et formules</h4>
        <ul>
          <li>
            <b>
              <u>Débit d’administration d’iode (DAI) :</u>
            </b>
          </li>
          <p className="text-indent">
            <b>
              DAI g/sec = Concentration du PdC (g/L) x Débit injection (L/sec)
            </b>
          </p>
          <p className="text-indent">
            Avec PdC = produit de contraste ; g = gramme ; L = litre ; sec =
            seconde.
          </p>
          <p className="text-indent">
            Le critère de qualité est d’obtenir un rehaussement vasculaire ≥ 300
            UH.
          </p>
          <p className="text-indent">
            Selon la littérature, le débit d’iode à 120 kV doit se situer entre
            1,2 et 1,6 g I/sec en vasculaire général et de 2 g I/ sec pour les
            coronaroscanners. Le tableau 1 donne ainsi les débits d’injection
            correspondants en fonction de la concentration du produit :
          </p>
          <img
            className="img-responsive centre"
            src="https://v1.clairance-creatinine.fr/PDC/images/vasculaire_tableau_1.jpg"
            alt="CIRTACI Tableau 1"
          />
          <figcaption className="figure-caption text-center">
            <i>
              <b>Tableau 1 </b>: Débit de l’injecteur en mL/sec en fonction du
              débit d’administration d’iode et de la concentration du produit.
            </i>
          </figcaption>
          <br />

          <li>
            <b>
              <u>
                Les temps physiologiques d’opacification par voie veineuse
                (valeurs moyennes indicatives dépendant du débit cardiaque et de
                l’état physiologique du patient).
              </u>
            </b>
          </li>
          <ul>
            <li>départ de l’injection à t = 0 sec</li>
            <li>tronc de l’artère pulmonaire : 10-12 sec</li>
            <li>ventricule gauche : 17 sec</li>
            <li>aorte crosse : 20 sec</li>
            <li>aorte abdominale : 30 – 35 sec</li>
            <li>membres inférieurs : 40 – 45 sec</li>
          </ul>
          <p className="text-indent">
            pour mémoire : temps portal : 70 sec, temps tardif : 3 à 5 min
          </p>
          <p className="text-indent">
            Ces temps conditionnent la <b>durée maximale d’injection</b> en
            fonction du site anatomique désiré. Il est en effet inutile de
            continuer à injecter après que le vaisseau d’intérêt a été opacifié,
            sauf dans les protocoles combinés (temps vasculaire et portal,
            phléboscanner…).
          </p>
        </ul>
        <h4>III. L’injection de sérum physiologique</h4>
        <p className="text-indent">
          {" "}
          après ce temps maximum est indispensable pour « pousser » le bolus
          restant dans la tubulure ou stagnant dans le réseau veineux proximal.
        </p>

        <h4>IV. Temps d’injection</h4>
        <p className="text-indent">
          {" "}
          Le temps d’acquisition dépendant fortement du type de scanner, on peut
          proposer la formule suivante :
        </p>
        <p className="text-indent">
          <b>
            {" "}
            Temps d’injection = Temps arrivée du PDC – (temps acquisition / 2)
          </b>
        </p>
        <p className="text-indent">
          Pour les scanners ultra rapides, où il faut avoir un volume
          d’injection légèrement plus important, le départ d’acquisition devra
          avoir un retard de 2 sec.
        </p>
        <p className="text-indent">
          En fonction de la formule précédente, le tableau 2 donne les
          principaux paramètres du protocole d’injection qu’il faudra savoir
          optimiser par la suite.
        </p>
        <img
          className="img-responsive centre"
          src="https://v1.clairance-creatinine.fr/PDC/images/vasculaire_tableau_2.jpg"
          alt="CIRTACI Tableau 2"
        />
        <figcaption className="figure-caption text-center">
          <i>
            <b>Tableau 2 </b>: Protocoles exemples pour différentes
            localisations anatomiques.
          </i>
        </figcaption>
        <br />

        <h4>V. Protocole Embolie Pulmonaire</h4>
        <p className="text-indent">
          {" "}
          Il s’agit d’un des protocoles les plus difficiles à réaliser chez tous
          les patients, car très sensible au débit cardiaque et à la corpulence.
          La figure 1 explique la chronologie du passage du produit de contraste
          au niveau thoracique.
        </p>
        <img
          className="img-responsive centre"
          src="https://v1.clairance-creatinine.fr/PDC/images/vasculaire_figure_1.jpg"
          alt="CIRTACI Figure 1"
        />
        <figcaption className="figure-caption text-center">
          <i>
            <b>Figure 1 </b>: Parcours du bolus de produit de contraste et temps
            d’acquisitions. Avec t = temps, ici en seconde après le début
            d’injection, t0 ; trc = tronc, G = gauche, abdo = abdominal. Le
            temps de balayage de la machine varie d’environ 3 secondes pour un
            64 barrettes à moins de 2 secondes pour les machines disposant de
            plus de 64 barettes.
          </i>
        </figcaption>
        <br />

        <p className="text-indent">
          Le produit de contraste met 10 secondes pour arriver jusqu’au tronc de
          l’artère pulmonaire. L’injection se fera donc{" "}
          <b>AU MAXIMUM sur 10 secondes</b>. Une fois la détection faite,
          automatique ou visuelle, la machine prend plusieurs secondes avant de
          démarrer l’acquisition (entre 4 et 7 secondes selon les
          constructeurs). Cela lui permet de monter en puissance, en vitesse de
          rotation et de se déplacer sur la première coupe. Dans le même temps,
          cela permet le rinçage avec du liquide physiologique et donc la
          récupération du volume résiduel dans la tubulure et dans les veines et
          rinçage du produit de contraste dans la sous-clavière et la veine cave
          supérieure limitant les artéfacts de blooming.{" "}
        </p>
        <p className="text-indent">
          {" "}
          L’optimisation du protocole se fera localement pour choisir un débit
          d’iode (entre 1,2 et 1,6 g I/sec. le plus souvent). Le tableau 3 donne
          un schéma d’injection selon la concentration en iode du produit
          utilisé et le débit d’injection.
        </p>
        <img
          className="img-responsive centre"
          src="https://v1.clairance-creatinine.fr/PDC/images/vasculaire_tableau_3.jpg"
          alt="CIRTACI Tableau 3"
        />
        <figcaption className="figure-caption text-center">
          <i>
            <b>Tableau 3 </b>: Protocoles embolie pulmonaire pour différents
            débits d’iode et un temps d’injection fixe de 10 sec.
          </i>
        </figcaption>
        <br />
        <p className="text-indent">
          <b> Règles à respecter :</b>
        </p>
        <ul>
          <li>Abord veineux le plus proximal possible,</li>
          <li>Utiliser un cathlon le plus gros possible (vert),</li>
          <li>
            En cas de mauvais état veineux, on sera souvent obligé de limiter le
            débit à 2 mL/sec et de compenser en augmentant la concentration.
          </li>
          <li>Apnée simple, PAS DE VALSALVA</li>
        </ul>

        <p className="text-indent">
          <b> Situations cliniques particulières :</b>
        </p>
        <ul>
          <li>
            Patients <b>jeunes, sportifs</b> et la <b>femme enceinte</b> : le
            débit cardiaque est plus élevé, il faut augmenter le débit d’iode.
          </li>
          <li>
            Patient <b>obèse</b> : un kilovoltage élevé (120 kV voire 140 kV)
            peut être nécessaire, ce qui diminue le pouvoir d’opacification de
            l’iode nécessitant une augmentation du débit d’administration d’iode
            (DAI) par augmentation du débit ou de la concentration ou des deux.
          </li>
          <li>
            <b>Patient âgé</b> : le débit cardiaque est diminué, il faut
            diminuer le débit d’iode. Le tableau 4 donne des indications des
            valeurs du protocole d’injection selon la situation clinique.
          </li>
        </ul>
        <br />
        <img
          className="img-responsive centre"
          src="https://v1.clairance-creatinine.fr/PDC/images/vasculaire_tableau_4.jpg"
          alt="CIRTACI Tableau 4"
        />
        <figcaption className="figure-caption text-center">
          <i>
            <b>Tableau 4 </b>: Protocole embolie pulmonaire pour des situations
            particulières. Ces volumes sont des volumes minimum. On peut ajuster
            le volume à la dizaine supérieure.
          </i>
        </figcaption>
        <br />
        <h4 id="optimisation_vasc">
          VI. Procédure de diminution des doses de produit de contraste
        </h4>

        <p className="text-indent">
          {" "}
          Voir également la fiche «{" "}
          <b>
            Optimisation des doses de produits de contraste en scanner -
            Oncologie
          </b>{" "}
          »
        </p>
        <p className="text-indent">
          {" "}
          La réduction du kilovoltage permet une réduction de la dose de
          rayonnement et de la quantité de produit de contraste. L’adaptation
          doit se faire en 2 grandes étapes :
        </p>
        <ul>
          <li>
            <b>
              On vérifiera préalablement à toute modification la qualité des
              protocoles utilisés (évaluation des pratiques).
            </b>
          </li>
          <ol>
            <li className="num">
              La dose doit être calculée par rapport au Débit d’administration
              d’iode (DAI) (cf paragraphes 1 et 2) avec pour objectif (critères
              de qualité), une opacification vasculaire ≥ 300 UH dans la lumière
              du vaisseau
            </li>
            <li className="num">
              Si cet objectif n’est pas atteint, il faut modifier le protocole :
            </li>
            <ol>
              <li className="alpha">
                Pas d’adaptation du DAI à l’examen et au patient.
              </li>
              <li className="alpha">
                Densité UH trop faible : regarder le délai d’acquisition, le
                bolus de sérum physiologique et le débit d’iode et adapter selon
                le problème détecté.
              </li>
              <li className="alpha">
                Densité UH trop élevée : timing ? regarder le délai
                d’acquisition et le débit d’iode et adapter selon le problème
                détecté.
              </li>
            </ol>
          </ol>
          <li>
            <b>
              La deuxième étape consiste ensuite à optimisation son protocole en
              baissant les kV puis baisser la dose d’iode
            </b>
          </li>
        </ul>

        <p className="text-indent">
          {" "}
          La baisse des kV permet de rapprocher le spectre d’énergie du faisceau
          de RX du K edge de l’iode, et donc d’augmenter le pouvoir
          d’atténuation du produit de contraste. L’efficacité en termes de
          contraste est donc identique pour une quantité moindre d’iode, et une
          plus faible dose d’exposition aux rayons X. Travailler (ce qui est
          possible avec la grande majorité du parc installé) avec un kilovoltage
          bas est un devoir de qualité et d’optimisation du rapport bénéfice
          /risque. Le but est toujours d’obtenir un rehaussement suffisant des
          structures opacifiées, contrôlé par la mesure des densités en UH (cf.
          supra) en utilisant le moins de dose de RX et de contraste possible
          (as low as diagnostically acceptable ou ADARA).
        </p>
        <p className="text-indent">
          {" "}
          Classiquement à 120kV, le débit d’iode (DAI) par seconde est de 1,2 à
          1,6 gI/ sec. à 100 kV le DAI pourrait être de 0,96 à 1,28 g I/sec et à
          80 kV entre 0,76 et 1 g I/sec.
        </p>
        <ol>
          <li className="alpha">
            Après avoir validé les pratiques habituelles.
          </li>
          <li className="alpha">
            Baisser de 120 à 100kv le kilovoltage utilisé en vasculaire et
            optimiser les algorithmes (reconstruction itérative, intelligence
            artificielle) pour compenser l’augmentation du bruit tout en évitant
            d’augmenter les mAs (réglages avec le constructeur).
          </li>
          <li className="alpha">
            Reprendre ensuite les protocoles validés mais en baissant le débit
            d’iode délivré/sec par palier de 10%.
          </li>
          <li className="alpha">
            Contrôle à chaque palier du respect des critères de qualité (cf
            supra) sur une ou deux journées sur toute une série de patients de
            toutes morphologies.
          </li>
          <li className="alpha">
            Après validation d’une étape, il est possible de continuer en
            baissant encore de 10% le taux d’iode délivré/sec.
          </li>
          <li className="alpha">
            Si la qualité est jugée insuffisante, on revient au palier
            antérieur.
          </li>
        </ol>

        <h4>VII. Synthèse</h4>
        <p className="text-indent">
          {" "}
          Le débit d’administration d’iode / seconde est l’élément clef en
          scanner vasculaire.
        </p>
        <p className="text-indent">
          {" "}
          L’obtention d’une densité du vaisseau étudié de 300 UH nécessite un
          DAI selon le kilovoltage utilisé de :
        </p>
        <ul>
          <li>
            <b>120 kV : 1,2 à 1,6 g I/sec</b>
          </li>
          <li>
            <b>100 kV : 1,0 à 1, 3 g I/sec</b>
          </li>
          <li>
            <b>80 kV : 0,8 à 1 g I/sec</b>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Vasculaire;
