import React, { useState, useEffect } from "react";
import ReactSlider from "react-slider";
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEraser,
  faCircleCheck,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import "./includes/styles_ref.css";
import useAutoSavePDConco from "./BDD/BDD_PDC_onco";
import PubDroitePDC from "./pubs/pub_droite_PDC";

const Oncologie = (props) => {
  let meta_title ="Optimisation de l'injection de produit de contraste au scanner (TDM) -- Oncologie"
  let meta_cannonical ="https://clairance-creatinine.fr/Oncologie"
  let meta_description ="Cet outil permet d'optimiser les doses de PDC au scanner en fonction de chaque patient, de son poids et de sa taille  "

  const [poids, setPoids] = useState("");
  const [poids2, setPoids2] = useState("");
  const [taille, setTaille] = useState("");
  const [kv, setKv] = useState("");
  const [concentration, setConcentration] = useState("");
  const [errorPoids, setErrorPoids] = useState(null);
  const [errorTaille, setErrorTaille] = useState(null);
  const [resultats, setResultats] = useState(null);
  const [attente, setAttente] = useState("ok");
  const [onco_Mosteller, setOnco_Mosteller] = useState(null);
  const [charge_iode, setCharge_iode] = useState("");
  const [unite, setUnite] = useState("");
  const [couleur_alerte, setCouleur_alerte] = useState("");
  const [obese, setObese] = useState("");
  const [problemePoids, setProblemePoids] = useState("");
  const [mode_calcul, setMode_calcul] = useState("");
  const [volume, setVolume] = useState("");
  const [debit, setDebit] = useState("");
  const [dureePerso, setDureePerso] = useState();
  //const [portal, setPortal] = useState(70);
  const [debitPerso, setDebitPerso] = useState("");
  const [showDebitPerso, setShowDebitPerso] = useState(false);
  const [quantiteIode, setQuantiteIode] = useState(120);
  const [limitPoids, setLimitPoids] = useState(null);
  const [limitDebit, setLimitDebit] = useState(null);
  let duree = 30
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    document.title =
      "Optimisation de l'injection de produit de contraste au scanner (TDM) -- Oncologie";
  }, []);

  const Reset = () => {
    setPoids("")
    setTaille("")
    setKv("")
    setConcentration("")
    setObese("")
    setAttente("oui")
    setDureePerso()
    setShowDebitPerso(false)
    
  };

  const donnees = {
    poids: poids,
    taille: taille,
    kv: kv,
    concentration: concentration,
    volume: volume,
    debit: debit,
    resultats: resultats,
  };
  //console.log("donnees", donnees)
  // C'est ici pour sauvegarder les résultats dans le BDD
  //  useAutoSavePDConco("donnees", donnees);

  const calculonco_Mosteller = () => {
    let calculMosteller = Math.sqrt((poids * taille) / 3600);
    setOnco_Mosteller(calculMosteller.toFixed(2));
  };

  const onco_debit = () => {
    setDebit(Number((volume / duree).toFixed(1)));
    //console.log("Dans onco_debit", debit);
  };

  const onco_debit_perso = () => {
    if (volume / debitPerso > 51) {
      setDureePerso(999);
    }
    if (volume / debitPerso <= 51) {
      setDureePerso(90);
    }
    if (volume / debitPerso <= 41) {
      setDureePerso(80);
    }
    if (volume / debitPerso <= 31) {
      setDureePerso(70);
    }
    //console.log("volume / debitPerso", volume / debitPerso);
  };

  const onco_volume = () => {
    if (kv === 120) {
      if (taille >= 120 && taille <= 250) {
        setPoids2(onco_Mosteller);
        setCharge_iode("18.6");
      } else {
        setPoids2(poids);
        setCharge_iode("0.5");
      }
    }
    let volume = Math.round(((poids2 * charge_iode) / concentration) * 1000, 0);
    setVolume(volume);

    let qteIode = poids2 * charge_iode;
    setQuantiteIode(qteIode.toFixed(1));

    // Tous les champs sont remplis
    if (
      isNaN(volume) ||
      volume === Infinity ||
      poids === "" ||
      kv === "" ||
      errorPoids !== "" ||
      errorTaille !== ""
    ) {
      setResultats(null);
    } else {
      setResultats(volume);
      setAttente(null);

      if (debitPerso) {
        onco_debit(); // pour afficher le débit dans l'input de modification du débit
        onco_debit_perso();
      } else {
        onco_debit();
      }
    }
  };

  useEffect(() => {
    if (poids === 49) {
      setPoids("");
    }
    if (taille === 144) {
      setTaille("");
    }

    if (poids === 120) {
      setLimitPoids(true);
    } else {
      setLimitPoids("");
    }

    if (debit > 2) {
      setLimitDebit(true);
      //setDebitPerso(parseFloat(debit))
    } else {
      setLimitDebit();
    }

    if ((poids < 35 || poids > 301) && poids !== "") {
      setErrorPoids("Poids incorect");
    } else {
      setErrorPoids("");
    }

    if ((taille < 120 || taille > 240) && taille !== "" && taille !== 0) {
      setErrorTaille("Taille incorecte");
    } else {
      setErrorTaille("");
    }

    calculonco_Mosteller();
    onco_volume();

    console.log("showDebitPerso", showDebitPerso);

    if (kv === 120) {
      setUnite("gI/kg");
      setObese("");

      if (taille >= 120 && taille <= 250) {
        setPoids2(onco_Mosteller);
        setCharge_iode("18.6");
        setUnite("gI/m²");
        setCouleur_alerte("#BFFFA0"); // vert
        setMode_calcul("Calcul optimisé en fonction du poids et de la taille");
        setProblemePoids("");
        //console.log("--------1111111111111------------", onco_Mosteller)
      } else if (poids <= 100 && (taille === "" || taille === 0)) {
        setPoids2(poids);
        setCharge_iode("0.5");
        setCouleur_alerte("#f0ad4e"); // orange
        setMode_calcul("Calcul fait uniquement avec le poids du patient");
        setProblemePoids("");
        //console.log("--------222222222222------------", setMode_calcul)
      } else if (poids >= 100 && (taille === "" || taille === 0)) {
        setPoids2(poids);
        setCharge_iode("0.5");
        setCouleur_alerte("#f0ad4e"); // orange
        setMode_calcul("Calcul fait uniquement avec le poids du patient");
        setProblemePoids(
          "Attention votre patient a un poids élevé et vous n'avez pas renseigné sa taille, vous risquez de surestimer la quantité à injecter"
        );
        //console.log("--------333333333333------------", setMode_calcul)
      } else {
        setPoids2(poids);
        setCharge_iode("0.5");
        setCouleur_alerte("#f0ad4e"); // orange
        setMode_calcul("Calcul fait uniquement avec le poids du patient");
        setProblemePoids("");
        //console.log("--------444444444444------------", setMode_calcul)
      }
    } else if (kv === 100) {
      setUnite("gI/kg");
      setObese("");
      if (taille >= 120 && taille <= 250) {
        setPoids2(onco_Mosteller);
        setCharge_iode("15");
        setUnite("gI/m²");
        setCouleur_alerte("#BFFFA0"); // vert
        setMode_calcul("Calcul optimisé en fonction du poids et de la taille");
        setProblemePoids("");
      } else if (poids <= 100 && (taille === "" || taille === 0)) {
        setPoids2(poids);
        setCharge_iode("0.4");
        setCouleur_alerte("#f0ad4e"); // orange
        setMode_calcul("Calcul fait uniquement avec le poids du patient");
        setProblemePoids("");
      } else if (poids >= 100 && (taille === "" || taille === 0)) {
        setPoids2(poids);
        setCharge_iode("0.4");
        setCouleur_alerte("#f0ad4e"); // orange
        setMode_calcul("Calcul fait uniquement avec le poids du patient");
        setProblemePoids(
          "Attention votre patient a un poids élevé et vous n'avez pas renseigné sa taille, vous risquez de surestimer la quantité à injecter"
        );
      } else {
        setPoids2(poids);
        setCharge_iode("0.4");
        setCouleur_alerte("#f0ad4e"); // orange
        setMode_calcul("Calcul fait uniquement avec le poids du patient");
        setProblemePoids("");
      }
    } else if (kv === 80) {
      setUnite("gI/kg");
      setObese("");
      if (taille >= 120 && taille <= 250) {
        setPoids2(onco_Mosteller);
        setCharge_iode("11");
        setUnite("gI/m²");
        setCouleur_alerte("#BFFFA0"); // vert
        setMode_calcul("Calcul optimisé en fonction du poids et de la taille");
        //console.log("--------444444444444------------", setMode_calcul)
      } else if (poids >= 90) {
        setPoids2(poids);
        setCharge_iode("0.3");
        setCouleur_alerte("#f2dede"); // rouge
        setObese(
          `Les protocoles à 80 kV ne sont actuellement pas adaptés aux patients obèses`
        );
        setMode_calcul("Calcul fait uniquement avec le poids du patient");
      } else if (poids <= 100 && (taille === "" || taille === 0)) {
        setPoids2(poids);
        setCharge_iode("0.3");
        setCouleur_alerte("#f0ad4e"); // orange
        setMode_calcul("Calcul fait uniquement avec le poids du patient");
        setProblemePoids("");
      } else {
        setPoids2(poids);
        setCharge_iode("0.3");
        setCouleur_alerte("#f0ad4e"); // orange
        setMode_calcul("Calcul fait uniquement avec le poids du patient");
        setProblemePoids("");
      }
    } else {
    }
  }, [calculonco_Mosteller, concentration]);

  useEffect(() => {
    setDebitPerso(parseFloat(debit));
  }, [debit]);

  const Resultats = () => {
    return (
      <>
        <div className="row flexAlignItemCentre">
          <div className={`ok ${showDebitPerso === true && "blur"}`}>
            <p className="examen">
              En oncologie, à <span className="bold">{kv} kv</span>
            </p>
            <p className="injection">
              {volume} ml à {debit} cc/s*
            </p>
            <p className="">Départ acquisition au temps portal: 70s</p>
          </div>
          {resultats !== null && (
            <>
              {limitDebit && (
                <div className="plus addDebitGroup">
                  <div className="">
                    <div className="InputAddOn">
                      <span className="InputAddOn-item addDebit">
                        Débit trop élevé pour mon patient ?
                      </span>
                      <input
                        className="InputAddOn-field addDebit"
                        value={debitPerso}
                        type="number"
                        step="0.2"
                        onChange={(debitPerso) => {
                          setDebitPerso(debitPerso.target.value);
                          setShowDebitPerso(true);
                          console.log("debitPerso", debitPerso.target.value);
                        }}
                      />
                      <button className="InputAddOn-item addDebit">cc/s</button>
                    </div>
                    {showDebitPerso === true && <>
                    <p className="injection">
                      {volume} ml à {debitPerso} cc/s**
                    </p>
                    {dureePerso === 999 ? (
                      <p className="tomato">
                        <b>Débit d'injection insuffisant !</b>
                      </p>
                    ) : (
                      <p className="examen">
                        <b>Départ acquisition au temps portal: {dureePerso}s</b>
                      </p>
                    )}
                    </>}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="plus">
          <p
            className="plusBouton"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {showMore
              ? " - Moins d'informations"
              : " + Plus d'informations et recommandations"}
          </p>
          {showMore ? <Details /> : null}
        </div>
      </>
    );
  };

  const Details = () => {
    return (
      <div className="plus">
        <p>
          Concentration : <span className="bold">{concentration} mgI/ ml</span>{" "}
        </p>
        <p>
          Charge en iode :{" "}
          <span className="bold">
            {charge_iode} {unite}
          </span>
        </p>
        <p>
          Quantité d’iode nécessaire :{" "}
          <span className="bold">{quantiteIode} g d'iode à injecter</span>
        </p>
        <p>
          Temps d'injection :{" "}
          <span className="bold">{(volume / debit).toFixed(1)} secondes</span>
        </p>
        <p>
          Surface corporelle : <span className="bold">{onco_Mosteller} m²</span>
        </p>

        <p className="plusDetail">
          -{" "}
          <span className="bold">
            Préchauffer le produit de contraste à 37°C
          </span>{" "}
          pour diminuer la viscosité et donc la pression d’injection.
        </p>
        <p className="plusDetail">
          - Rinçage avec{" "}
          <span className="bold">30 à 40 ml de sérum physiologique</span> pour
          pousser le PdC résiduel de la tubulure et du réseau veineux proximal.
        </p>
        <p className="plusDetail">
          - Départ acquisition au temps portal: 70 s si moins de 70 ans /
          Au-delà, départ acquisition = âge du patient. (90 sec. Maximum)
        </p>
        <p className="plusDetail">
          - L'injection doit être terminée avant la fin de l’acquisition.
        </p>
      </div>
    );
  };

  return (
    <div className="flex1">
    <Helmet>
      <title>{meta_title}</title>
      <link rel="canonical" href={meta_cannonical} />
      <meta name='description' content={meta_description} />
    </Helmet>
      <div className="resultats_vasculaire guerbet">
        {attente !== null && (
          <div className="attente">
            <p className="attente_texte">
              Les résultats s'afficheront içi quand les critères indispensables
              seront renseignés
            </p>
          </div>
        )}

        {resultats !== null && (
          <>
            <Resultats />
          </>
        )}
      </div>

      <div className="main guerbetText">
        <div className="gauche">
          <h2 className="titre">
            Mon patient :{" "}
            <FontAwesomeIcon
              className="gomme"
              icon={faEraser}
              onClick={() => {
                Reset();
              }}
            />
          </h2>
          <div className="onco_patient">
            <div className="zzzz">
              <div className="InputAddOn">
                <span className="InputAddOn-item guerbet">Poids</span>
                <input
                  className="InputAddOn-field"
                  placeholder="kg"
                  type="number"
                  value={poids}
                  onChange={(poids) => {
                    setPoids(poids.target.value);
                    console.log("Poids", poids.target.value);
                  }}
                />
                <button className="InputAddOn-item guerbet">kg</button>
              </div>
              <ReactSlider
                className="customSlider"
                thumbClassName="customSlider-thumb"
                trackClassName="customSlider-track"
                min={49}
                max={120}
                value={poids}
                defaultValue={0}
                onChange={(value) => setPoids(value)}
              />
            </div>

            <div className="zzzz">
              <div className="InputAddOn">
                <span className="InputAddOn-item guerbet">Taille</span>
                <input
                  className="InputAddOn-field"
                  placeholder="cm"
                  value={taille}
                  onChange={(taille) => {
                    setTaille(taille.target.value);
                  }}
                />
                <button className="InputAddOn-item guerbet">cm</button>
              </div>

              <ReactSlider
                className="customSlider"
                thumbClassName="customSlider-thumb"
                trackClassName="customSlider-track"
                min={144}
                max={240}
                value={taille}
                onChange={(value) => setTaille(value)}
              />
            </div>
          </div>

          {limitPoids !== "" && (
            <p className="errorPatient">
              Rentrez manuellement le poids si supérieur
            </p>
          )}

          {errorPoids !== "" && <p className="errorPatient">{errorPoids}</p>}
          {errorTaille !== "" && <p className="errorPatient">{errorTaille}</p>}

          <h2 className="titre">Tension du tube</h2>

          <div className="radio2">
            <label
              className={`label_group_row w20 ${kv === 80 ? "checked" : ""}`}
            >
              80
              <input
                className="radio"
                label="80"
                name="kv"
                type="radio"
                defaultChecked={kv === 80}
                onClick={() => setKv(80)}
              />
            </label>
            <label
              className={`label_group_row w20 ${kv === 100 ? "checked" : ""}`}
            >
              100
              <input
                className="radio"
                label="100"
                name="kv"
                type="radio"
                defaultChecked={kv === 100}
                onClick={() => setKv(100)}
              />
            </label>
            <label
              className={`label_group_row w20 ${kv === 120 ? "checked" : ""}`}
            >
              120
              <input
                className="radio"
                label="120"
                name="kv"
                type="radio"
                defaultChecked={kv === 120}
                onClick={() => setKv(120)}
              />
            </label>
          </div>

          <h2 className="titre">Concentration en mg/ml</h2>
          <div className="radio2">
            <label
              className={`label_group_row w20 ${
                concentration === 300 ? "checked" : ""
              }`}
            >
              300
              <input
                className="radio"
                name="concentration"
                label="300"
                type="radio"
                defaultChecked={concentration === 300}
                onClick={() => setConcentration(300)}
              />
            </label>
            <label
              className={`label_group_row w20 ${
                concentration === 350 ? "checked" : ""
              }`}
            >
              350
              <input
                className="radio"
                name="concentration"
                label="350"
                type="radio"
                defaultChecked={concentration === 350}
                onClick={() => setConcentration(350)}
              />
            </label>
            <label
              className={`label_group_row w20 ${
                concentration === 370 ? "checked" : ""
              }`}
            >
              370
              <input
                className="radio"
                name="concentration"
                label="370"
                type="radio"
                defaultChecked={concentration === 370}
                onClick={() => setConcentration(370)}
              />
            </label>
            <label
              className={`label_group_row w20 ${
                concentration === 400 ? "checked" : ""
              }`}
            >
              400
              <input
                className="radio"
                name="concentration"
                label="400"
                type="radio"
                defaultChecked={concentration === 400}
                onClick={() => setConcentration(400)}
              />
            </label>
          </div>

          {obese !== "" && <p className="error">{obese}</p>}

          {resultats !== null && (
            <div
              className="textCalcul"
              style={{
                backgroundColor: `${couleur_alerte}`,
                paddingRight: "5px",
              }}
            >
              {couleur_alerte !== "#BFFFA0" ? (
                <FontAwesomeIcon icon={faTriangleExclamation} />
              ) : (
                <FontAwesomeIcon icon={faCircleCheck} />
              )}{" "}
              {mode_calcul}
              <p> {problemePoids}</p>
            </div>
          )}
        </div>

        <div className="droite">
        <img
                    className="Guerbet"
                    src={`https://www.formation-continue-imagerie.fr/images/bup/2023/logo_color_WEB.png`}
                    alt="Guerbet"
                    width="250px"
                />
        </div>
      </div>

      <div className="bottom">
        <p>
          * Les résultats sont calculés à partir des recommandations du CIRTACI
          de la SFR. Ils sont donnés à titre indicatif.
        </p>
      </div>

      <h2 className="centre">Références</h2>
      <p className="centre">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.radiologie.fr/sites/www.radiologie.fr/files/medias/documents/CIRTACI%20Fiche%20Ge%CC%81ne%CC%81ralite%CC%81s%20ONCO_5_3_0.pdf"
        >
          <img
            src="https://v1.clairance-creatinine.fr/PDC/images/cirtaci.jpg"
            alt="CIRTACI"
            title="CIRTACI"
          />
        </a>
      </p>

      <div className="well clear" id="optimisation">
        <h3 className="centre">
          Optimisation des doses de produits de contraste en tomodensitometrie
          Oncologie
        </h3>
        <h4>I. Généralités</h4>
        <p className="text-indent">
          La justification et l’optimisation sont, comme pour les radiations
          ionisantes, à la base de l’utilisation des produits de contraste
          iodés. Ils ne doivent être injectés que s’ils peuvent apporter des
          informations utiles au diagnostic, avec la quantité d’iode nécessaire
          et suffisante. L’évolution technologique des scanners permet une
          acquisition rapide, une zone d’exploration large, une modulation des
          kV et des mAS, et une optimisation de la qualité d’image et des doses
          de RX grâce aux nouveaux algorithmes (reconstruction itérative et
          bientôt intelligence artificielle).{" "}
        </p>
        <p className="text-indent">
          <b>
            L’injection d’une dose fixe de produit de contraste (PdC) pour tous
            les examens n’a plus lieu d’être
          </b>
          .{" "}
        </p>
        <p className="text-indent">
          L’injection de produit de contraste iodée{" "}
          <b>doit toujours être calculée en fonction du poids du patient</b>.{" "}
        </p>
        <p className="text-indent">
          Enfin, l’abaissement du kilovoltage permet de diminuer la dose
          d’exposition aux rayons X et la dose de produit de contraste
          nécessaire.
        </p>
        <p className="text-indent">
          Une optimisation des protocoles d’injection adaptée à chaque machine
          doit être réalisée.
        </p>
        <p className="text-indent">
          Cette fiche se propose une démarche à suivre méthodique pour optimiser
          les examens selon les protocoles classiques à 120 kV puis en réduisant
          les kV à 100 kV et envisage le cas particulier des patients obèses.
        </p>

        <h4>II. Définitions et formules</h4>
        <ul>
          <li>
            <b>
              <u>Charge en Iode</u>
            </b>{" "}
            = Dose d’Iode par Kg de poids corporel <b>g I/kg</b>.
          </li>
          <p className="text-indent">
            Classiquement entre 0,4 et 0,6 gI/kg, c’est cette dose qui sera à
            optimiser pour les protocoles d’opacification des parenchymes.
          </p>
          <li>
            <b>
              <u>Volume à injecter</u>
            </b>{" "}
            = [charge en Iode (g/kg) x Poids (kg) / Concentration du PdC (g/L)],
            résultat en litres. Multiplier par 1000 pour l’avoir en{" "}
            <b>millilitres</b>.
          </li>
          <li>
            <b>
              <u>Débit d’administration d’iode</u>
            </b>{" "}
            : DAI g/sec = Concentration du PdC (g/L) x Débit (L/sec),cette
            variable est à optimiser en vasculaire (cf. fiche vasculaire).
          </li>
        </ul>
        <p className="text-indent">
          Temps physiologiques d’opacification pour une injection par voie
          veineuse :
        </p>
        <ul>
          <li>départ de l’injection à t = 0 sec</li>
          <li>tronc de l’artère pulmonaire : 10-12 sec</li>
          <li>cœur gauche : 22-25 sec</li>
          <li>aorte abdominale : 30 – 35 sec</li>
          <li>temps portal : 70 sec</li>
          <li>temps néphrographique : 90-100 sec</li>
          <li>temps tardif : 3 à 5 min</li>
        </ul>

        <h4>III. Prococoles d’opacification des parenchymes (Oncologie)</h4>
        <p className="text-indent">
          La charge en iode gouverne le rehaussement désiré des organes contrôlé
          par des critères de qualité (CQ).
        </p>
        <h5>1- La dose standard à 120 kV est 0,5 gI/kg</h5>
        <p className="text-indent">
          <b>
            L’objectif est d’obtenir un rehaussement d’environ 50 UH du foie au
            temps portal
          </b>{" "}
          (par rapport au foie non injecté). Si le scanner est injecté d’emblée,
          on cherchera une densité hépatique supérieure ou égale à 110 UH. Si le
          foie est stéatosique, on cherchera une densité splénique supérieure ou
          égale à 120 UH.
        </p>
        <p className="text-indent">
          On calcule donc la dose en fonction du poids du patient, puis le
          volume à injecter en fonction de la concentration du produit.
        </p>
        <p className="text-indent">
          Ex. patient de 70 kg injecté avec un PdC à 350 mgI/mL : <br />
          0,5 gI/kg x 70 kg = 35 g d’iode donc 100 ml d’un produit concentré à
          350 mgI/mL
        </p>
        <p className="text-indent">
          On construit facilement un tableau (Tableau 1).
        </p>
        <img
          className="img-responsive"
          src="https://v1.clairance-creatinine.fr/PDC/images/oncologie_tableau_1.jpg"
          alt="CIRTACI tableau 1"
        />
        <figcaption className="figure-caption text-center">
          <i>
            <b>Tableau 1 </b>: Calcul du volume à injecter en fonction de la
            dose, du poids du patient et de la concentration du produit.
            <br />
            Exemples pour une dose de 0,5 g I /kg. Un calculateur est à
            télécharger sur le site de la SFR / Cirtaci
          </i>
        </figcaption>
        <br />
        <p className="text-indent">
          Il est possible d’utiliser la <b>surface corporelle (SC)</b> plutôt
          que le poids mais cela est surtout utile en cas de surcharge pondérale
          ou d’obésité (cf. chapitre IV) :
        </p>
        <p className="text-indent">
          <b>A 120 kV : Dose d’Iode g = SC *18,6 g I</b> (Kondo 2013).
        </p>
        <h5>2- Débit d’injection</h5>
        <p className="text-indent">
          Si un temps vasculaire est nécessaire avant le temps portal, un débit
          maximal en fonction de l’état veineux est souhaitable.
        </p>
        <p className="text-indent">
          En oncologie, un <b>temps d’injection de 30 sec</b> permet un débit
          d’iode satisfaisant pour une imprégnation portale correcte.
        </p>
        <p className="text-indent">
          Dans tous les cas, l’injection doit être terminée avant la fin de
          l’acquisition.
        </p>
        <p className="text-indent">
          On calcule donc le débit en fonction du volume pour avoir un temps
          d’injection à 30 sec (Tableau 2).
        </p>
        <img
          className="img-responsive"
          src="https://v1.clairance-creatinine.fr/PDC/images/oncologie_tableau_2.jpg"
          alt="CIRTACI tableau 2"
        />
        <figcaption className="figure-caption text-center">
          <i>
            <b>Tableau 2 </b>: Pour garder une durée d’injection constante à 30
            secondes, le débit augmente avec le volume à injecter. Un
            calculateur est à télécharger sur le site de la SFR / Cirtaci.
          </i>
        </figcaption>
        <br />
        <p className="text-indent">
          Ces débits théoriques ne sont pas toujours applicables, en particulier
          en cas de mauvais état veineux.
        </p>
        <p className="text-indent">
          On sera souvent obligé de limiter le débit à 2 mL/sec et compenser en
          augmentant la concentration et en rallongeant le temps portal à 80
          sec.
        </p>

        <h5>3- Autres facteurs d’optimisation</h5>
        <ul>
          <li>
            <b>Rinçage avec du sérum physiologique.</b>
          </li>
          <li>
            30 à 40 ml de sérum sont suffisants pour pousser le PdC résiduel de
            la tubulure et du réseau veineux proximal.
          </li>
          <li>
            Chauffage du produit de contraste à 37°C. Ceci permet de diminuer la
            viscosité et donc la pression d’injection.
          </li>
        </ul>
        <h5>4- Procédure de diminution de dose de produit de contraste</h5>
        <p className="text-indent">
          La réduction du kilovoltage permet une réduction de la dose de RX et
          de la quantité utile de produit de contraste.
        </p>
        <ul>
          <li>
            <b>
              On vérifiera dans un premier temps la qualité des protocoles de
              routine (évaluation des pratiques).
            </b>
            <ol>
              <li className="num">
                La dose DOIT être calculée par rapport au poids (cf paragraphes
                I et II) et les Critères de qualité validés :
                <ul>
                  <li>Vasculaire ou temps artériel : ≥ 300 UH dans vaisseau</li>
                  <li>
                    Parenchyme au temps portal :
                    <ul>
                      <li className="num">
                        rehaussement de 50 à 60 UH/ foie non injecté
                      </li>
                      <li className="num">
                        si injection d’emblée : Foie ≥ 110 UH
                      </li>
                      <li className="num">
                        Si stéatose hépatique : Rate ≥ 120UH
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="num">
                Prendre des mesures correctives si :
                <ul>
                  <li>Pas d’adaptation au poids du patien</li>
                  <li>
                    Densité UH trop faible : timing ? sérum physiologique ? dose
                    pour parenchyme ? Taux d’iode délivré/sec si vasc :
                    Correction
                  </li>
                  <li>
                    Densité UH trop élevée : timing ? dose pour parenchyme ?
                    Taux d’iode délivré/sec si vasc : Correction{" "}
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>Optimisation : baisser les kV puis baisser la dose d’iode.</li>
          <p className="text-indent">
            La baisse des kV permet de rapprocher le spectre d’énergie du
            faisceau de RX du K edge de l’iode, et donc d’augmenter le pouvoir
            d’atténuation du produit de contraste.
          </p>
          <p className="text-indent">
            L’efficacité est identique pour une quantité moindre d’iode, et une
            plus faible dose d’exposition aux rayons X.
          </p>
          <p className="text-indent">
            Le but est d’obtenir un rehaussement suffisant des structures
            opacifiées, contrôlé par la mesure des densités en UH (cf supra) en
            utilisant le moins de dose de RX et de contraste possible (as low as
            diagnostically acceptable ou ADARA).
          </p>
          <p className="text-indent">
            Schématiquement une baisse de 20 kV diminue la dose de RX et d’iode
            de 20 %.
          </p>
          <p className="text-indent">
            Ainsi à 100 Kv la dose d’iode pourrait être environ de 0,4 g I/kilo
            et à 80 kV de 0,32 g I/kilo. Des valeurs identiques sont retrouvées
            pour l’adénocarcinome du pancréas (Noda 2016).
          </p>
          <p className="text-indent">
            En cas d’utilisation de la surface corporelle, la dose à 100 kV
            serait : Iode g = SC * 15 g I et à 80 kV Iode g = SC x 11 g/m 2
            (Goshima 2014, 2016).
          </p>

          <p className="text-indent">
            <b>Méthode générale :</b>
          </p>
          <ol>
            <li className="alpha">
              Après avoir validé les pratiques habituelles,
            </li>
            <li className="alpha">
              Baisser de 120 à 100 kV le kilovoltage utilisé en abdominopelvien
              et optimiser les algorithmes (reconstruction itérative,
              intelligence artificielle) pour compenser l’augmentation du bruit
              et éviter d’augmenter les mAs (réglages avec le constructeur),
            </li>
            <li className="alpha">
              Reprendre les protocoles validés mais en baissant la charge en
              iode <b>par palier</b> de 10% (dose d’iode délivrée ou le débit
              d’iode délivré/sec),
            </li>
            <li className="alpha">
              Contrôle du respect des contrôles de qualité (cf. supra){" "}
              <b>
                sur une ou deux journées sur toute une série de patients de
                toutes morphologies
              </b>
              ,
            </li>
            <li className="alpha">
              Seconde étape en baissant encore de 10% la dose d’iode délivrée ou
              le taux d’iode délivré/sec,
            </li>
            <li className="alpha">
              Contrôle du respect des contrôles de qualité (cf. supra) sur une
              ou deux journées,
            </li>
            <li className="alpha">
              <b>
                Si la qualité est jugée insuffisante, on revient au palier
                antérieur.
              </b>
            </li>
          </ol>
          <p className="text-indent">
            <b>
              Remarques : Les examens de contrôle abdomino-pelviens tous les 3
              mois de patients ayant un suivi carcinologique permettent une
              comparaison aisée des densités parenchymateuses et vasculaires
              chez le même patient après avoir modifié les protocoles.
            </b>
          </p>
        </ul>

        <h4>IV. Obésité et dose d’iode en oncologie</h4>
        <p className="text-indent">
          L’obtention d’un rehaussement hépatique optimal (50 UH) après
          injection d’un contraste iodé est particulièrement difficile chez les
          patients obèses, principalement en raison du bruit plus élevé qui
          réduit la visibilité des lésions hépatiques. L’amélioration technique
          des scanners (capteur, reconstruction itérative, intelligence
          artificielle) a permis une réduction considérable de l’incidence du
          bruit tout en réduisant l’exposition aux rayons X mais un protocole
          d’injection de contraste adéquat reste très important.
        </p>
        <p className="text-indent">
          Un patient obèse présente une proportion élevée de graisse corporelle
          et un volume sanguin proportionnellement petit par rapport à son poids
          total, ainsi qu’un petit compartiment extracellulaire bien perfusé.
        </p>
        <p className="text-indent">
          Le{" "}
          <b>
            timing du rehaussement et donc de l’injection n’est pas modifié{" "}
          </b>
          en cas d’obésité car le volume sanguin et le débit cardiaque
          augmentent proportionnellement.
        </p>
        <p className="text-indent">
          La graisse corporelle est moins vascularisée que les organes et les
          muscles viscéraux et le{" "}
          <b>volume sanguin n’est pas linéaire au poids et à la taille.</b>
        </p>

        <p className="text-indent">
          <b>
            La dose proportionnelle au poids total surestime la quantité à
            injecter.
          </b>
        </p>

        <p className="text-indent">
          Ainsi, la posologie linéaire proportionnelle au poids peut entraîner
          une surestimation de la quantité de produit de contraste nécessaire
          chez les patients en surcharge pondérale ou obèses.
        </p>
        <p className="text-indent">
          Doses proportionnelles à la surface corporelle ou au poids maigre
        </p>
        <p className="text-indent">
          D’autres paramètres plus pertinents ont été proposés pour tenir compte
          de l’obésité et d’éviter le surdosage :
        </p>
        <ul>
          <li>la surface corporelle (formule),</li>
          <li>
            le poids maigre (formule ou beaucoup mieux par balance
            impédancemètre),
          </li>
          <li>
            l’indice de masse corporelle n’est pas adapté car ce n’est pas un
            indice de taille du corps.
          </li>
        </ul>
        <p className="text-indent">
          Nous retiendrons la surface corporelle qui est la plus utilisée car
          plus simple :
        </p>
        <p className="text-indent">Formule de surface corporelle (Mosteller)</p>
        <img
          className="img_brute"
          src="https://v1.clairance-creatinine.fr/PDC/images/oncologie_Mosteller.png"
          alt="CIRTACI tableau oncologie_Mosteller"
        />
        <img
          className="img-responsive"
          src="https://v1.clairance-creatinine.fr/PDC/images/oncologie_tableau_3.jpg"
          alt="CIRTACI tableau 3"
        />
        <figcaption className="figure-caption text-center">
          <i>
            <b>Tableau 3 </b>: Estimation de la surface corporelle en m2 en
            fonction du poids et de la taille. Un calculateur est à télécharger
            sur le site de la SFR / Cirtaci.
          </i>
        </figcaption>
        <br />

        <p className="text-indent">
          Classiquement à 120 kV, la dose de contraste nécessaire pour les
          lésions hépatiques est de 0,5 g I/kg (Heiken 1995, Bae 2010) A 100 KV
          la dose d’iode est de l’ordre de 0,4 g I/kg (cf. supra).
        </p>
        <p className="text-indent">
          En cas d’utilisation de la surface corporelle, la dose d’iode est à
          120 kV de Iode g = SC *18,6 g I (Kondo 2013) et à 100 kV : Iode g = SC
          * 15 g I.
        </p>

        <p className="text-indent">
          Les protocoles à 80 kV ne sont pas actuellement adaptés aux patients
          obèses.
        </p>

        <p className="text-indent">
          Le tableau 4 montre la quantité d’iode nécessaire selon que l’on
          calcule en fonction du poids du patient ou de sa surface corporelle.
          Dans ce cas la diminution de la quantité d’iode est significative
          (environ 18%).
        </p>
        <img
          className="img-responsive"
          src="https://v1.clairance-creatinine.fr/PDC/images/oncologie_tableau_4.jpg"
          alt="CIRTACI tableau 4"
        />
        <figcaption className="figure-caption text-center">
          <i>
            <b>Tableau 4 </b>: Quantité d’iode nécessaire selon que l’on calcule
            en fonction du poids du patient ou de sa surface corporelle. Dans ce
            cas la diminution de la quantité d’iode est significative (environ
            18%). Dose en g Iode.
          </i>
        </figcaption>
        <br />

        <h4>V. Synthèse</h4>
        <p className="text-indent">
          La dose de contraste doit toujours être adaptée au poids du patient
        </p>
        <p className="text-indent">
          En cas de surcharge pondérale ou d’obésité, il est préférable de
          calculer la dose en fonction de la surface corporelle pour éviter un
          surdosage.
        </p>
        <img
          className="img-responsive"
          src="https://v1.clairance-creatinine.fr/PDC/images/oncologie_tableau_5.jpg"
          alt="CIRTACI tableau 5"
        />
        <figcaption className="figure-caption text-center">
          <i>
            <b>Tableau 5 </b>: Résumé les doses d’iode recommandées en fonction
            du kilovoltage utilisé, le 80 kV étant réservé aux patients de poids
            normal
          </i>
        </figcaption>
        <br />
      </div>
    </div>
  );
};

export default Oncologie;
