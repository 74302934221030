import { useEffect } from "react";
import {addPDCvasc} from '../../api/stats'

export function useAutoSavePDCvasc(storageId, data, delay = 2000) {
    useEffect(() => {
        const timeoutId = setTimeout(() => {

            if (data.resultats !== null) {
                console.log("Sauvegarde des données PDC vasc", data.resultats);
                localStorage.setItem(storageId, JSON.stringify(data));
                addPDCvasc(data)
            }
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [delay, data, storageId]);
}


export default useAutoSavePDCvasc